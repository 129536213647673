import React from "react"
import Layout from "../components/layout"
import Infocard from "../components/infocard"
import style from "../styles/sites.module.css"
import { graphql } from "gatsby"
import SEO from "../components/seo"

function makeUp({ data }) {
  return (
    <Layout>
      <SEO
        title="Make Up"
        description="Egal ob Hochzeitstag, Ballbesuch oder ein besonderer Anlass: ich zaubere dir ein stilvolles und typgerechtes Make-Up."
      />
      <h1>Make-Up vom Profi</h1>
      <p>
        Mit dem richtigen Make-Up fühlst du dich schön, selbstbewusst und kannst
        strahlen! Egal ob Hochzeitstag, Ballbesuch oder ein besonderer Anlass:
        ich zaubere dir ein stilvolles und typgerechtes Make-Up.{" "}
      </p>
      <section className={style.services}>
        {data.makeUp.nodes.map(item => {
          return <Infocard data={item} key={item.title} />
        })}
      </section>
    </Layout>
  )
}
export const query = graphql`
  {
    makeUp: allContentfulMakeUp(sort: { order: ASC, fields: order }) {
      nodes {
        text {
          childMarkdownRemark {
            html
          }
        }
        title
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`

export default makeUp
